/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PostList from '../components/PostList'
import SEO from '../components/SEO/SEO'
import BlogNav from '../components/BlogNav'
import Layout from '../components/LayoutTag'

const TagRouteTemplate = ({ data }) => {
    const posts = data.allMarkdownRemark.edges.map((p) => p.node)
    return (
        <div className="container">
            <section className="is-blog-section">
                <div className="container">
                    <BlogNav />
                    <PostList posts={posts} />
                </div>
            </section>
        </div>
    )
}

TagRouteTemplate.propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
}

class TagRoute extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            location: { pathname: '' },
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            this.setState = { location: { pathname: window.location.pathname } }
        }
    }

    render() {
        let data
        let pageContext
        if (this.props.data !== null) {
            data = this.props.data
            pageContext = this.props.pageContext
        }
        return (
            <Layout data={data} location={this.state.location}>
                <SEO frontmatter={data.markdownRemark.frontmatter} />
                <TagRouteTemplate data={data} pageContext={pageContext} />
            </Layout>
        )
    }
}

export default TagRoute

TagRoute.propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
}

export const pageQuery = graphql`
    query TagPage($langKey: String!, $tag: String!) {
        site {
            siteMetadata {
                languages {
                    langs
                    defaultLangKey
                }
            }
        }
        markdownRemark {
            frontmatter {
                title
                slug
            }
        }
        allMarkdownRemark(
            limit: 1000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
                frontmatter: {
                    templateKey: { ne: "message" }
                    lang: { eq: $langKey }
                }
                fields: {
                    langKey: { eq: $langKey }
                    tagSlugs: { elemMatch: { tag: { eq: $tag } } }
                }
            }
        ) {
            totalCount
            edges {
                node {
                    frontmatter {
                        author
                        imageAlt
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1380) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                        title
                        description
                        date
                        slug
                        tags
                    }
                    fields {
                        langKey
                        slug
                        tagSlugs {
                            tag
                            link
                        }
                    }
                    excerpt
                    timeToRead
                }
            }
        }
    }
`
