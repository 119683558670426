import React from 'react'
import PropTypes from 'prop-types'
import PostListItem from '../PostListItem'

const PostList = ({ posts }) => {
    return (
        <div className="columns is-multiline">
            {posts.map((post) => (
                <PostListItem key={post.id} post={post} />
            ))}
        </div>
    )
}

PostList.propTypes = {
    posts: PropTypes.array,
}

export default PostList
