import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import Time from '../Time'
import Img from 'gatsby-image'

const PostListItem = ({ post }) => {
    return (
        <div className="column is-4" key={post.id}>
            <div className="container is-mobile is-transition is-bottom-border">
                <article className="tile is-child white-ter">
                    <Link to={post.fields.slug}>
                        <figure className="is-3by2">
                            <Img
                                className="is-image-radius"
                                alt={post.frontmatter.imageAlt}
                                fluid={
                                    post.frontmatter.image.childImageSharp.fluid
                                }
                            />
                        </figure>
                    </Link>
                    <div className="has-article-content-padding">
                        <div className="level is-mobile">
                            <div className="level-left">
                                <p className="is-size-7 has-text-primary has-text-weight-bold">
                                    {'#' + post.frontmatter.tags}
                                </p>
                            </div>
                            <div className="level-right">
                                <div className="is-size-7 has-text-primary has-text-weight-bold">
                                    <Time
                                        pubdate
                                        langKey={post.fields.langKey}
                                        date={post.frontmatter.date}
                                    />
                                </div>
                            </div>
                        </div>
                        <p>
                            <Link
                                className="has-text-weight-bold is-size-5 has-article-title"
                                to={post.fields.slug}
                            >
                                {post.frontmatter.title}
                            </Link>
                        </p>
                        <p>
                            <Link
                                className="content has-text-grey has-text-weight-medium is-size-6"
                                to={post.fields.slug}
                            >
                                {post.excerpt}
                            </Link>
                        </p>
                    </div>
                </article>
            </div>
        </div>
    )
}

PostListItem.propTypes = {
    post: PropTypes.shape({
        id: PropTypes.string,
        fields: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            langKey: PropTypes.string.isRequired,
        }),
        frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired,
            author: PropTypes.string.isRequired,
            imageAlt: PropTypes.string.isRequired,
            image: PropTypes.object.isRequired,
            tag: PropTypes.array,
            tags: PropTypes.array,
            date: PropTypes.string.isRequired,
        }),
        excerpt: PropTypes.string.isRequired,
        timeToRead: PropTypes.number,
    }),
}

export default PostListItem
